body {
  // background: linear-gradient(125deg, #41b5c8, #8041c8);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 7s ease infinite;
  -moz-animation: AnimationName 7s ease infinite;
  animation: AnimationName 7s ease infinite;
}

#player {
    width: 500px;
    margin: 200px auto;
    display: block;
    border-radius: 5px;
    background-color:white;
    position: relative;
    text-align: center;
    box-shadow: 0 13px 27px -5px rgba(50,50,93,.15), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);



    .MuiLinearProgress-colorPrimary {
      background-color: #d5d7ff !important;
    }

    .MuiLinearProgress-barColorPrimary {
      background-color:#6a6dc7 !important;
    }

    .info {
		margin: 20px 0;
        h1 {
          color:rgb(45, 45, 45);
          font: sans-serif;
          font-weight: 400;
          margin: 0;
          position: relative;
          font-size: 20px;
          font-family: 'Roboto Mono', monospace;
        }
    }

        
    

    .playPauseContainer {
        width: 50px;
        height: 50px;
        border-radius: 100px;
        margin: 20px auto;
        display: block;
        text-align: center;
        cursor: pointer;
        position: relative;
        background: linear-gradient(125deg, #cd7ef1, #8af0ec);
        background-size: 400% 400%;
        text-align: center;
        transition: all .2s ease-out;

        box-shadow: 0 13px 27px -5px rgba(50,50,93,.15), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);

        -webkit-animation: AnimationName 7s ease infinite;
        -moz-animation: AnimationName 7s ease infinite;
         animation: AnimationName 7s ease infinite;

        img {
			width: 15px;
			height: 15px;
			position: absolute;
			margin: 0;
			display: inline;
			top: 18px;
			left: 18px;
			display: block;
         }

         &:hover {
           cursor: pointer;
           opacity: 0.8;
           transform: translateY(-4px);
            box-shadow: 0 13px 27px -5px rgba(50,50,93,.35), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
         }
    }

    .progressBarContainer {
      // margin: 0 50px;
      // bottom: 97px;
      // position: relative;
      // border-radius: 50px;
    }

  }

.collectedBars {
  // display: block;
  width: 100%;
  height: 180px;
  margin: 20px auto;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  // overflow: hidden;
  // transform: rotatex(180deg);
  // transform-origin: top;
}

.singleBar {
  background-color:rgb(254, 68, 133);
  border-radius: 20em;
  width: 7px;
  height: 7px;
  display: inline-block;
  margin: 0 5px;
  transition: 0.1s ease all;
}

.albumImage {
  width: 150px;
  height: 150px;
  box-shadow: 0 13px 27px -5px rgba(50,50,93,.15), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  top: -90px;
  margin: 0 auto;
  z-index: 10000;
  border-radius: 5px;
  background: linear-gradient(125deg, #cd7ef1, #8af0ec);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 7s ease infinite;
    -moz-animation: AnimationName 7s ease infinite;
    animation: AnimationName 7s ease infinite;

  .css-selector {
    background: linear-gradient(125deg, #cd7ef1, #8af0ec);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 7s ease infinite;
    -moz-animation: AnimationName 7s ease infinite;
    animation: AnimationName 7s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}


  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  p {
    padding: 20px;
  }
}


#startButton {
  margin: 10px;
  width: 50px;
  height: 50px;
  background-color: rgb(0, 255, 66);
}

.frequencyBands {
  padding: 12px;
  margin: 8px;
  transform: rotatex(180deg);
  transform-origin: top;
}