.about {
    margin: 150px auto 30px auto;
    padding: 0 30px;
    backdrop-filter: blur(2px);
    border: 1px solid rgb(148, 148, 148);
    border-radius: 3px;
    background-color: white;

    b {
        font-size: 16px;
    }
}
