.home {
    margin: 150px auto 35px auto;
    padding: 0 30px;
    border: 1px solid rgba(148, 148, 148, 0);
}


.home h2 {
    // font-size: 31px;
    margin: 40px 0 15px 0;
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-family: Inter;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0px;
    font-weight: 600;
    color: #5e5e5e;
    /* text-shadow: 2px 3px 2px rgb(0 0 0 / 10%); */
    display: inline-block;
}

.darkGray {
    color: #343a40; // 
}

.home {
    p {
        a {
            color: #343a40;
            font-weight: 700;
            letter-spacing: -0.2px;
            transition: 0.1s all ease;
    
            &:hover {
                color: #8888f0;
                // text-decoration: none;
            }
        }
    }
}

.home p {
    font-size: 18px;
    margin: 0;
    font-weight: 400;
    /* letter-spacing: 0.3px; */
    font-family: 'inter', sans-serif;
    line-height: 27px;
    color: #80807B;
}

    .home .xyz {
        color: #343a40;
        text-decoration: none;
        position: relative;
        top: 29px;
        left: 7px;
        transition: 0.3s all ease;
        opacity: 0;
    }

    .showup {
        left: 13px !important;
        opacity: 1 !important;
    }

    .xyz img {
        width: 14px;
        position: relative;
        top: 1px;
        margin-right: 2px;
    }




@media (max-width: 768px) {


}
