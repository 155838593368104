body {
    margin: 0;
    background-color: #FAFAFA;
}

/* font-family: 'Lora', serif;
font-family: 'Open Sans', sans-serif; */

canvas {
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
}

section {
    margin: 0 auto;
}

.container {
    margin: 0 auto;
    max-width: 700px;
}

.homeNav {
    position: relative;
}

.ad {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    // user-select: none;

    display: inline;
    position: relative;

    div {
        position:relative;
    }
}

    

.nav {
    font-weight: 500;
    position: relative;
    top: 20px;
    right: 0;
    font-family: "Inter", sans-serif;

    

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        display: inline-block;
        font-size: 20px;
        margin-right: 33px;
        position: relative;
    }

    li:first-child {
        // color: #818181;
        color: #343a40;
    }

    li:first-child {
        margin-left: 0;
    }

    a {
        text-decoration: none;
        // text-underline-offset: 2px;
        position: relative;
        display: inline-block;
        // padding: 10px 15px;
        transition: 1.5s all ease-out;
        color: #343a40;

        &:after {
            border-bottom: 0px solid #343a40;
        }

        &:before {
            content: "";
            width: 0;
            height: 2px;
            position: absolute;
            bottom: -1px;
            right: 0px;
            background: #343a40;
            animation: ani 1s ease infinite;
            transition: all 0.3s ease-out;
            border-radius: 1.5px;
            z-index: 100;
          }
          &:hover {
            &:before {
              width: 100%;
              left: 0px;
              animation: ani 1s ease infinite;
              background: #343a40;
            }
          }
    }

    .bolder {
        font-weight: 700;
    }
}

    .activeLinkStyle {

            &:after {
                content: "";
                border-bottom: 2px solid #343a40 !important;
                border-radius: 10000px;
                position: absolute;
                bottom: -1px;
                left: 0px;
                right: 0;
            }

            &:before {
                width: 100%;
            }
            &:hover {
                &:before {
                    width: 100%;
                    left: 0px;
                }
            }


        // text-decoration: underline !important;
        
        transition: 0.5s all ease-out;
    }

    @-webkit-keyframes ani {
        0%{background-position:0% 99%}
        50%{background-position:100% 2%}
        100%{background-position:0% 99%}
    }
    @keyframes ani {
        0%{background-position:0% 99%}
        50%{background-position:100% 2%}
        100%{background-position:0% 99%}
    }

    .seafoam {
        &:before {
            animation: ani 1s ease infinite;
            background: linear-gradient(231deg, #00e5ff 0%, #3CD3AD 51%, #00e5ff 100%);
          }
          &:hover {
            &:before {
            background: linear-gradient(231deg, #00e5ff 0%, #3CD3AD 51%, #00e5ff 100%);
            }
          }
        
    }

    .sorbet {
        &:before {
            animation: ani 1s ease infinite;
            background: linear-gradient(231deg, #ff6ca4 0%, #ff9b7c 51%, #ff6ca4 100%);
          }
          &:hover {
            &:before {
            background: linear-gradient(231deg, #ff6ca4 0%, #ff9b7c 51%, #ff6ca4 100%);
            }
          }
        
    }

    // .smooth {
    //     background: linear-gradient(231deg, #c491ff 0%, #568ad8 51%, #c491ff 100%);
    // }

    // .rose {
    //     background: linear-gradient(231deg, #ffafbd 0%, #ffc3a0 51%, #ffafbd 100%);
    // }

    // .airplane {
    //     background: linear-gradient(231deg, #40bcd9 0%, #6dd5ed 51%, #40bcd9 100%);
    // }

.photos {
    display: flex;
    clear: both;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    img {
        width: 32.4%;
        display: inline-block;
        border-radius: 3px;
    }
}

.content {
    padding: 0;
    font-family: "Open Sans", serif;
    font-weight: 400;
    padding-top: 50px;

    .caption {
        color: #B8B8B8;
        font-size: 14px;
        font-family: "Open Sans", serif;
    }

    h3 {
        font-family: 'Inter', sans-serif;
        color: #343a40;
        font-weight: 600;
    }

    p {
        line-height: 27px;
        color: #343a40;
    }

    b {
        font-weight: 600;
        color: #343a40;
        font-size: 17px;
    }

    a {
        text-decoration: none;
        color: #9d9df2;
        font-weight: 700;
        transition: 0.1s all ease;

        &:hover {
            color: #8888f0;
            text-decoration: none;
        }
    }

    .description {
        margin-top: 0;
    }

    ul {
        // padding-inline-start: 40px;
        list-style: none;
        padding: 0;
        margin: 0;
        padding-inline-start: 0;

        li { 
            padding-left: 2em;
            text-indent: -.7em;
            line-height: 27px;
            padding-bottom: 0.85em;
            color: #343a40;
            vertical-align:middle
        }

        li::before {
            content: "•";
            padding-right: 5px;
            color: #8084a7;
            position: relative;
            right: 10px;
        }
    }

    

}

footer { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    position: relative;
    align-items: center;
    bottom: 10px;

    
    .social-links {
        float: right;
        position: relative;
        top: 5px;
    }

        .social-links img {
            width: 18px;
            margin: 0 5px;
            opacity: 0.6;
            transition: 0.3s all ease;
        }

            .social-links img:hover {
                opacity: 0.9;
                transform: rotate(-25deg);
            }

        .social-links a {
            margin: 0 10px;
        }
}


@media (max-width: 768px) {

    .home {
        margin: 10px 0 !important;

    }

    .about, .now {
        margin: 10px 10px 0 10px !important;
        padding: 0 20px !important;
    }

    .nav {
        display: block;
        position: relative;
        position: relative;
        top: 20px;

        a:before {
            height: 0;
            width: 0;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0px 0 0 0;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-content: center;
            align-items: center;
        }
    }

    .nav li {
        // margin-top: 10px;
        margin-right: 0 !important;
    }

    .header {
        padding-bottom: 50px;
    }

    footer {
        padding: 20px 30px 40px 30px;
        bottom: 0px;

        .social-links {
            float: none;

            a:first-child {
                margin-left: 0;
            }
        }
    }

    // .audio-player {
    //     display: none !important;
    // }

    .gray {
        left: 0px !important;
    }


}

.gray {
    color: #a2a2a2;
    position: relative;
    left: 3px;
}

.links {
    font-size: 13px;
    color: #a2a2a2;
    position: relative;
    bottom: 2px;
}

/* @media handheld {
.my-image:hover {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
}
} */
